<script>
import ResourceFrame from "../components/ResourceFrame.vue";
import DynamicTitle from "../../common/components/DynamicTitle.vue";
import { SuawInputGroup, SuawParagraph, SuawHeading } from "@suaw/suaw-component-library";
export default {
  name: "ResourcesMethod",
  components: { ResourceFrame, DynamicTitle, SuawInputGroup, SuawParagraph, SuawHeading }
};
</script>

<template>
  <ResourceFrame>
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-method-heading">Our <b>Method</b></h1>
      <SuawHeading
        class="suaw-method-intro"
        level="3"
        content="At Shut Up & Write!, we know that writing thrives in a community. With structured sessions focused on dedicated writing time and open conversation, we encourage writers to dive into their projects without fear of judgment."
      />
      <h2 class="suaw-method-subtitle">
        What Makes It Different?
      </h2>
      <SuawParagraph
        text="<b>Community Focus:</b> At Shut Up & Write!, we believe in creating a vibrant community around the often solitary act of writing. It's not just about the finished product; it's about the connections we forge along the way. By gathering with the same group regularly, you cultivate real relationships through shared intentions and collective creativity."
      />
      <SuawParagraph
        text="<b>No Critiques:</b> A hallmark of SUAW events is the absence of read-alouds, critiques, and feedback. We're here for writing, pure and simple. There's no pressure to share your work with the group, but there are plenty of opportunities to build connections and share experiences with fellow writers."
      />
      <SuawParagraph
        text="<b>Healthy Writing Habits:</b> Our focus isn't on hitting a specific word count; it's about making consistent progress on your writing project. With the support of community accountability and regular attendance, you'll develop sustainable writing practices that keep you moving forward. We believe that creative expression is a vital component of mental health, and scheduling time to write is an important way to nurture your well-being."
      />
      <DynamicTitle :before="['The']" :after="['Method']" size="medium" weight="bold" />
      <div class="suaw-method-guide">
        <ol class="suaw-method-guide__list">
          <li class="suaw-method-guide__item">
            <h2 class="suaw-method-guide__heading">Connect and Collaborate</h2>
            <ul class="suaw-method-guide__sublist">
              <li>Connect with other people who want to write, either in your local community or online, with the goal of forming a supportive writing group.</li>
              <li>Before you create a new group, see if you can join an existing one that works for you.</li>
              <li>
                Cultivate a safe and encouraging space where you and your fellow writers can make meaningful progress, share challenges, and celebrate victories together.
              </li>
            </ul>
          </li>

          <li class="suaw-method-guide__item">
            <h2 class="suaw-method-guide__heading">Choose Your Writing Space</h2>
            <ul class="suaw-method-guide__sublist">
              <li>Find a writing space that helps you focus and minimizes distractions.</li>
              <li>If you're meeting in person, think cozy cafes, libraries, or dedicated writing spaces.</li>
              <li>For online sessions, create an environment that signals your brain it's time to write, free from interruptions.</li>
            </ul>
          </li>

          <li class="suaw-method-guide__item">
            <h2 class="suaw-method-guide__heading">Set a Regular Writing Time</h2>
            <ul class="suaw-method-guide__sublist">
              <li>Establish a regular schedule for your writing sessions—consistency is the secret ingredient to forming a solid writing habit.</li>
              <li>Start with hour-long sessions and add time for social connection before and after. Be flexible and discover what works best for you.</li>
            </ul>
          </li>

          <li class="suaw-method-guide__item">
            <h2 class="suaw-method-guide__heading">Set Achievable Goals</h2>
            <ul class="suaw-method-guide__sublist">
              <li>
                At the beginning of each session, set clear writing goals for yourself—whether it's reaching a word count, completing a scene, or making strides on your
                project.
              </li>
              <li>Share your goals with the group to create a sense of accountability and support.</li>
            </ul>
          </li>

          <li class="suaw-method-guide__item">
            <h2 class="suaw-method-guide__heading">Write Without Distractions</h2>
            <ul class="suaw-method-guide__sublist">
              <li>When it's time to write, turn off all notifications and set distractions aside.</li>
              <li>
                Encourage everyone to dive deep into their writing during the session. Focus solely on the task at hand. You can also incorporate techniques like the Pomodoro
                method for short, focused bursts of writing followed by quick breaks.
              </li>
            </ul>
          </li>

          <li class="suaw-method-guide__item">
            <h2 class="suaw-method-guide__heading">Don't Edit While You Write</h2>
            <ul class="suaw-method-guide__sublist">
              <li>Let go of perfectionism! Write your rough draft without editing.</li>
              <li>Make sure not to edit while you write. If you are using a session to write, just write. If you are using a session to edit, just edit.</li>
            </ul>
          </li>

          <li class="suaw-method-guide__item">
            <h2 class="suaw-method-guide__heading">Reflect and Celebrate</h2>
            <ul class="suaw-method-guide__sublist">
              <li>At the end of your session, take a moment to reflect on what you've achieved.</li>
              <li>Share your progress with the group and celebrate both the big wins and the small victories!</li>
            </ul>
          </li>

          <li class="suaw-method-guide__item">
            <h2 class="suaw-method-guide__heading">Stay Engaged and Supportive</h2>
            <ul class="suaw-method-guide__sublist">
              <li>Keep the energy alive by interacting during breaks and fostering connections throughout the event.</li>
              <li>Stay engaged with your writing community between sessions. Share resources, writing tips, and inspiration to help each other flourish as writers!</li>
            </ul>
          </li>
        </ol>
      </div>
      <h2 class="suaw-method-subtitle">
        Why It Works:
      </h2>
      <SuawParagraph
        text="<b>Establishing a Practice:</b> Regular sessions create a routine that helps keep projects moving forward. The more you write, the more ideas flow."
      />
      <SuawParagraph text="<b>Creating Accountability:</b> Sharing goals with the group motivates you to stay committed." />
      <SuawParagraph text="<b>Building Connections:</b> Seeing familiar faces regularly builds camaraderie, encouraging support and collaboration among writers." />
      <SuawParagraph
        text="<b>Nurturing Growth:</b> Consistent attendance allows you to evolve as a writer, track your progress, and celebrate achievements within a supportive community."
      />
      <h2 class="suaw-method-subtitle">
        In a Nutshell
      </h2>
      <SuawParagraph
        text="At Shut Up & Write!, we combine the power of community with focused writing time to create a welcoming space where creativity can thrive. Here, writers find not just a routine, but a rhythm, and build a regular writing practice over time. When you commit to showing up, both for yourself and for your fellow writers, you keep everyone moving forward. We're all about celebrating each other's journeys, sharing ideas, and nurturing a culture where every voice matters. So come join us—when you show up for your own writing, you also show up for your fellow writers."
      />
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-method-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-method-intro {
  color: var(--sem-color-neutral-dark);
}
.suaw-method-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
//list
.suaw-method-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);
  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__item::marker {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 145%;
  }
  &__heading {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 145%;
  }
  &__sublist {
    list-style-type: disc;
    padding-left: 20px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    li {
      text-indent: 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 145%;
    }
  }
}
</style>
